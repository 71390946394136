import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn'
import { ChartingModule } from '~/modules/SDK/chart4/ChartingModule'

/**
 * # 取出可以為 Charting 選擇策略的函式
 *
 * ### Chart2 OK
 *
 * ### Chart4 OK
 */
export const useChartingSelectStrategyFn = (
  charting: ChartTypes.ChartingV2Instance | ChartingModule,
) =>
  useChartingFn(charting, {
    chart2: chart2 => (config: ChartTypes.StrategyConfig) => {
      const chart = chart2.getState()
      chart.widget?.setSymbol(config.symbol || chart.symbol, config.interval as any, () => {
        //
      })

      return chart.changeIndicators(config.indicators)?.then(() => {
        config.panesRatio && chart.changePaneRatio(config.panesRatio)
      })
    },
    chart4: chart4 => (config: ChartTypes.StrategyConfig) => {
      chart4.setStrategy(config)
      return chart4.updateFromStrategyState()
    },
  })
