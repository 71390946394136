import { css } from '@emotion/react'
import React, { memo } from 'react'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import {
  meCheckHandlerAgentWeb,
  meCheckHandlerAgentWebStock,
} from '~/modules/SDK/me/meCheckHandlerAgentWeb'
import { LoginView } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/LoginView'
import { NoSubscriptView } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/NoSubscriptView'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { MediaBanner } from '~/modules/auth/components/AgentCarouselSubscriptionCheck/MediaBanner'
import { CarouselMediaData } from './MediaData'
import type { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel'

/**
 * ## 此組件可由 {@link Preset_LoginPageOfCarousel} 取代
 *
 * ## 此組件內部同時驗了 web 或 web_stock 因此不再建議使用，以免未來採雷
 *
 * @deprecated
 */
export const AgentCarouselSubscriptionCheck = memo<
  ReactProps<{ resources: CarouselMediaData[]; opacity?: number }>
>(function CarouselSubscriptionCheck(props) {
  const { meFirebaseState } = useMeStore()
  const isWebStock = meCheckHandlerAgentWebStock.useCheck()
  const isWeb = meCheckHandlerAgentWeb.useCheck()

  const theme = useThemeStore(t => t.theme)

  const legitUser = isWebStock || isWeb

  if (legitUser) return null
  // 沒有 firebaseMe 保證是沒登入，登入 firebase 但沒有訂閱相關商品表示權限不夠。
  const view = !meFirebaseState ? <LoginView /> : !legitUser ? <NoSubscriptView /> : null

  const viewImg = !meFirebaseState || !legitUser ? <MediaBanner {...props} /> : null

  const opacity = props.opacity ?? 0.8
  return (
    <div
      css={css`
        position: fixed;
        ${flex.v.allCenter};
        width: 100%;
        height: 100%;
        background-color: ${theme === 'dark'
          ? `rgba(20, 20, 20, ${opacity})`
          : `rgba(255, 255, 255, ${opacity})`};

        z-index: 1300;
      `}
    >
      {viewImg}
      <div
        css={css`
          margin-top: 0px;
          z-index: 1500;
          position: relative;
        `}
      >
        {view}
      </div>
    </div>
  )
})
