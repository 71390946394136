import { css, Global } from '@emotion/react'
import React, { Fragment, memo, useState } from 'react'
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss'
import { proxy, useSnapshot } from 'valtio'
import { NotifyModule } from '~/modules/SDK/NotifyModule/NotifyModule'
import dayAPI from '~/utils/dayAPI'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Alert, Slide } from '@mui/material'
import { scrollbar2Css } from '~/css/scrollbarCss'

const notify1 = new NotifyModule({ name: 'notify_morerich' })

const notifyMessageStore = proxy({
  index: 0,
})

type BasicProps = {
  stateColor?: boolean
}

const mainContainer = css`
  ${pureGrid};
  width: 100%;
  height: 100%;
  grid-template-rows: 30px calc(100% - 30px);
  grid-template-columns: 80px calc(100% - 80px);
  font-size: 15px;
`

const timeButtonContainer = css`
  ${scrollbar2Css};
  ${flex.v.crossCenter};
  border-right: 1px solid #555555;
  grid-column: 1 / 2;
  grid-row: 2;
`

const messageContainer = css`
  ${scrollbar2Css};
  grid-column: 2 / 2;
  grid-row: 2;
`

const titleContent = css`
  width: 100%;
  grid-row: 1;
  background-color: #444444;
  ${flex.h.allCenter};
`

const TimeButtonStyled = styled.div<BasicProps>`
  ${flex.h.allCenter};
  width: 70px;
  height: 30px;
  background-color: #2a2d2f;
  border-radius: 5px;
  border: 1px solid ${props => (props.stateColor === true ? '#00ffff' : '#2a2d2f')};
  margin: 2.5px 0px;
  cursor: pointer;
  &:hover {
    background-color: #474d57;
  }
`

/** 訊息時間切換按鈕 */
export const MessageTimeButton = memo(function TimeButtom(props: {
  timeText: string
  messageNumber: number
}) {
  const notifyMessageState = useSnapshot(notifyMessageStore)
  const onState = props.messageNumber === notifyMessageState.index

  return (
    <TimeButtonStyled
      className={onState ? ManualSignalClasses.TimeButtonOnstate : ManualSignalClasses.TimeButton}
      stateColor={onState}
      onClick={() => (notifyMessageStore.index = props.messageNumber)}
    >
      {props.timeText}
    </TimeButtonStyled>
  )
})

export const ManualSignal = memo<ReactProps<{ agent: string; limit: number }>>(
  function ManualSignal_(props) {
    notify1.store.useLogs({ agent: props.agent, limit: props.limit }, { pollingInterval: 5000 })

    const state1 = useSnapshot(notify1.store)
    /** 過濾訊息:只顯示當日訊息 */
    const messageContext = state1.logs.filter(
      datum => dayAPI(datum.datetime).format('MM-DD') === dayAPI().format('MM-DD'),
    )
    /** 狀態:目前選取陣列中第幾則訊息 limit=5 0~4 */
    const notifyMessageStateNumber = useSnapshot(notifyMessageStore)
    /** HH:mm 時間格式 */
    const notifyTime = state1.logs.map(datum => dayAPI(datum.datetime).format('HH:mm'))
    /** 訊息內容 */
    const notifyMessageContent = messageContext.map(datum => datum.content)

    const [snackbarState, setSnackbarState] = useState(false)
    const [lastTime, setLastTime] = useState(notifyTime[0])

    //一開始載入網頁時不跳出mui通知 只在最新發訊時間變化時跳出通知
    if (lastTime !== notifyTime[0]) {
      setLastTime(notifyTime[0])
      //lastTime初始為undefined
      lastTime !== undefined && setSnackbarState(true)
      notifyMessageStore.index = 0
    }

    return (
      <div css={mainContainer}>
        <div
          css={titleContent}
          className={ManualSignalClasses.TitleContainer}
        >
          時間按鈕
        </div>
        <div
          css={titleContent}
          className={ManualSignalClasses.TitleContainer}
        >
          訊息內容
        </div>
        <div
          css={timeButtonContainer}
          className={ManualSignalClasses.ContentBorder}
        >
          {messageContext.map((datum, index) => {
            return (
              <MessageTimeButton
                key={index}
                messageNumber={index}
                timeText={dayAPI(datum.datetime).format('HH:mm')}
              />
            )
          })}
          {/** 測試用 */}
          {/* <div onClick={() => setSnackbarState(true)}>
          <MessageTimeButton
            key={99}
            messageNumber={99}
            timeText={'測試用'}
          />
        </div> */}
          {/** 測試用 */}
        </div>
        <div css={messageContainer}>
          {messageContext.length === 0
            ? '今天還沒有訊息'
            : notifyMessageContent[notifyMessageStateNumber.index]}
        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={snackbarState}
          autoHideDuration={6000}
          onClose={() => setSnackbarState(false)}
          key={'bottom' + 'left'}
        >
          <Alert severity='info'>{'時間' + notifyTime[0] + ' 您有新的訊息'}</Alert>
        </Snackbar>
      </div>
    )
  },
)

export const ManualSignalClasses = {
  TimeButton: `${ManualSignal.name}-TimeButton`,
  TimeButtonOnstate: `${ManualSignal.name}-TimeButtonOnstate`,
  TitleContainer: `${ManualSignal.name}-TitleContainer`,
  ContentBorder: `${ManualSignal.name}-ContentBorder`,
}
